:root {
  --dark-text: #011a2d;
  --navbar-shadow: #a1a1a1;
  --red: #e20000;
  --dark-red: #500000;
  --blue: rgb(143, 179, 214);
  /* --blue: rgb(15, 105, 186, 0.5); */
  --light-blue: rgb(15, 105, 186, 0.25);
  --white: #f7f6f2;
  --section-heading-weight: 700;
  --section-heading-size: 50px;
  --body-heading-weight: 500;
  --body-heading-size: 36px;
  --body-text-weight: 400;
  --body-text-size: 18px;

  overflow-x: clip;

  color: var(--dark-text);
}

h1 {
  font-weight: var(--section-heading-weight);
  font-size: var(--section-heading-size);
}

h2 {
  font-weight: var(--section-heading-weight);
  /* font-size: var(--section-heading-size); */
}
body {
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "proxima-nova", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--white);
}

* {
  margin: 0;
  padding: 0;
  /* scroll-behavior: smooth; */
  scroll-padding-top: 80px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.red {
  color: var(--white);
  background-color: var(--red);
}

.common-link {
  color: var(--red);
  text-decoration: none;
}

.common-link:hover {
  color: var(--dark-red);
}

.my--ul {
  padding-left: 15px;
}
