.landing-page--container {
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  background-color: var(--white);
  background-image: url("../../public/images/infinite-tile.jpg");
  background-size: contain;
  /*
    background: linear-gradient(
      0.25turn,
      url("../../public/images/infinite-tile.jpg"),
      rgba(0, 0, 0, 0) 50%,
      url("../../public/images/infinite-tile.jpg")
    ); */
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      var(--white) 50%,
      rgba(0, 0, 0, 0)
    ),
    url("../../public/images/infinite-tile.jpg");
  /* background: linear-gradient(0.25turn, #3f87a6, #ebf8e1, #f69d3c); */

  height: 100vh;
  width: 100vw;

  z-index: 10;

  -webkit-transition: opacity ease;
  transition: opacity ease;
}

.landing-page--logo {
  object-fit: contain;
  max-width: 100vw;
  margin: 3vh 0;
}

.blue-text {
  font-size: var(--body-heading-size);
  font-weight: var(--section-heading-weight);
  color: var(--blue);
}

.mock-1 {
  background-color: var(--red);
  height: 18vw;
  width: 18vw;
  position: absolute;
  top: 0;
  left: 0;
}
.mock-2 {
  background-color: var(--red);
  height: 30vh;
  width: 100px;
  position: absolute;
  border-radius: 100px;
  top: 20px;
  right: -50px;
}
.mock-3 {
  background-color: var(--blue);
  height: 200px;
  width: 400px;
  position: absolute;
  bottom: 0;
  left: 5vw;
}
.mock-4 {
  background-color: var(--red);
  height: 200px;
  width: 200px;
  bottom: 0;
  position: absolute;
  right: 30px;
}

.enter-arrow {
  position: absolute;
  bottom: 5vh;
  font-size: var(--body-heading-size);
  font-weight: var(--section-heading-weight);
  cursor: pointer;
  color: var(--red);
  font-style: none;
}

.enter-arrow:hover {
  color: var(--dark-red);
}

.landing-page--top-text {
  position: relative;
  top: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.landing-page--bottom-text {
  position: relative;
  bottom: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.landing-page--brown-risd {
  display: flex;
}

.landing-page--brown-risd h3 {
  margin: 0 5px;
}

@media (max-width: 500px) {
  .landing-page--brown-risd {
    flex-direction: column;
  }
  .enter-arrow {
    position: relative;
    bottom: 15vh;
  }
}

@keyframes fade-out {
  from {
    opacity: 100%;
  }
  to {
    opacity: 0%;
  }
}
