.card--container {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0 6px 10px var(--navbar-shadow);
  position: relative;
  background-color: var(--white);
  margin-bottom: 15px;
}

.card--title {
  color: var(--red);
}

.card--body-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card--body-items p {
  margin: 0;
  padding: 0;
}
.vendor--container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0 6px 10px var(--navbar-shadow);
  position: relative;
  background-color: var(--white);
  margin-bottom: 15px;
}

.vendor--container img {
  max-width: 40%;
}

.vendor--container div {
  display: flex;
  justify-content: center;
}

.closed-div {
  padding: 20px;
}

.tt--container {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0 6px 10px var(--navbar-shadow);
  position: relative;
  background-color: var(--white);
  margin-bottom: 15px;
}

.tt--person {
  margin-bottom: 10px;
}

.tt--img {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.tt--container a {
  color: var(--red);
  cursor: pointer;
}
.tt--img img {
  max-width: 100%;
  max-height: 50vh;
}

.tts--container {
  margin: auto;
  width: 60%;
}

@media (max-width: 800px) {
  .tts--container {
    width: 75%;
  }
}

@media (max-width: 600px) {
  .tts--container {
    width: 90%;
  }
}

.card--img {
  max-width: 100%;
  max-height: 400px;
  margin: auto;
}
