.workshop-leader {
  font-weight: 800;
  color: var(--blue);
}

.workshop--container {
  padding-top: 20px;
  margin: auto;
  width: 50%;
  z-index: 1;
  position: relative;
}

.ws--button {
  border: none;
  padding: 10px;
  font-size: var(--body-heading-size);
  /* font-size: 20px; */
  font-weight: var(--section-heading-weight);
  font-family: "proxima-nova", sans-serif;
  border-radius: 10px;
  /* background-color: var(--red); */
  color: var(--red);

  box-shadow: 5px 5px 5px var(--navbar-shadow);
  background-color: var(--white);
  margin: 0 5px;
}

.ws--button-select {
  border: none;
  padding: 10px;
  font-size: var(--body-heading-size);
  font-weight: var(--section-heading-weight);
  font-family: "proxima-nova", sans-serif;
  border-radius: 10px;
  background-color: var(--red);
  color: var(--white);

  box-shadow: inset 5px 5px 5px #bb0606;

  margin: 0 5px;

  /* box-shadow: 5px 5px 5px var(--navbar-shadow); */
}

.ws--button:hover {
  background-color: var(--dark-red);
  color: var(--white);
  cursor: pointer;
}

.ws--button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  z-index: 2;
  position: relative;
}

.ws--session-text {
  /* background-color: var(--white); */
  /* padding-left: 10px; */
  z-index: 5;
  position: relative;
  border-radius: 10px;
  color: var(--white);

  z-index: 5;
}

.day-block {
  background-color: var(--blue);
  padding: 20px 20px 10px 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  position: relative;
  z-index: 5;
  box-shadow: 5px 5px 5px var(--navbar-shadow);
}

.workshop--card {
  background-color: var(--white);

  border-radius: 5px;
  margin: 0 0 30px;
  padding: 15px;
  z-index: 5;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  box-shadow: 0 6px 10px var(--navbar-shadow);
}

.workshops--container {
  margin: auto;
  width: 50%;
}

@media (max-width: 1000px) {
  .workshop--container {
    width: 70%;
  }
}

@media (max-width: 800px) {
  .workshops--container {
    width: 75%;
  }
}

@media (max-width: 700px) {
  .workshops--container {
    width: 100%;
  }

  .ws--button {
    font-size: 20px;
  }

  .ws--button-select {
    font-size: 20px;
  }
}

.workshop--tag {
  background-color: var(--dark-text);
  color: white;
  padding: 4px;
  border-radius: 5px;
  display: inline;
  font-weight: var(--section-weight);
  margin-right: 5px;
}

.workshop--tag-container {
  margin: 5px 0;
}

.workshop--loc {
  margin-top: 10px;

  text-decoration: underline;
}

.tt--loc {
  text-decoration: underline;
}

.workshop--loc-link {
  color: var(--red);
}

.workshop--loc-link:hover {
  color: var(--dark-red);
}

.event--link {
  color: var(--red);
}

.event--link:hover {
  color: var(--dark-red);
}
