.hamburger-menu {
  cursor: pointer;
}

.hamburger--container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  padding-left: 20px;
}

.hamburger--container:hover .hamburger--line {
  background-color: var(--dark-red);
}

.hamburger--line {
  height: 6px;
  width: 36px;
  background-color: var(--red);
  margin: 4px;
}

.navbar--gray {
  position: fixed;
  height: 100vh;
  width: 100vw;
  /* background-color: rgba(1, 26, 45, 0.25); */
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 1;
  top: 0;
  left: 0;
  display: none;
}

.navbar--container {
  position: fixed;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  left: 0;
  bottom: 0;
  width: 0;
  height: 100%;
  box-shadow: 5px 5px 10px var(--navbar-shadow);
  justify-content: space-between;
  overflow: hidden;
  z-index: 2;
}

.navbar-options--container {
  height: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* padding-top: 0;
  margin: 0; */
}

.navbar--option {
  cursor: pointer;
  padding: 4vh 0 4vh 10%;
}

.navbar--option:hover h3 {
  color: var(--red);
}

.navbar--option-curr {
  cursor: pointer;
  padding: 5vh 0 5vh 10%;
}

.navbar--option-curr h3 {
  color: var(--dark-text);
}

.navbar--option-curr:hover h3 {
  color: var(--red);
}

.navbar--option-text {
  font-size: var(--body-text-size);
  font-weight: var(--section-heading-weight);
  color: var(--blue);
}

.header--container {
  box-shadow: 0 5px 15px var(--navbar-shadow);
  background-color: var(--white);
  margin: 0;
  width: 100vw;
  height: 80px;
  /* padding: 0 20px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  left: 0;
  max-width: 100vw;
  z-index: 8;
}

.header-container {
  box-shadow: 0 5px 15px var(--navbar-shadow);
  background-color: var(--white);
  margin: 0;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  max-width: 100vw;
  z-index: 8;
  height: 80px;
}

.register-now {
  background-color: var(--red);
  color: var(--white);
  width: 100%;
  text-align: center;
  display: none;
}

.register-now a {
  color: var(--white);
  cursor: pointer;
  font-weight: var(--body-heading-weight);
}

.header--inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  max-width: 100vw;
  width: 100vw;
  position: relative;
}

.logo--container {
  height: 100%;
  width: auto;
  cursor: pointer;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.logo--img {
  height: 100%;
  width: auto;
}

@keyframes open-nav {
  from {
    width: 0;
  }
  to {
    width: 400px;
  }
}
@keyframes close-nav {
  from {
    width: 400px;
  }
  to {
    width: 0;
  }
}

.header--top-right {
  padding-right: 20px;
}

.header--container-container {
  display: flex;
  flex-direction: column;
}

.register-text {
  color: var(--red);
  cursor: pointer;
  font-weight: var(--section-heading-weight);
}

.register-text:hover {
  color: var(--dark-red);
}

.hamburger--container {
  z-index: 1;
}
@media (max-width: 600px) {
  .header--top-right {
    display: none;
  }

  .register-now {
    display: block;
  }

  .header-inner {
    height: 65px;
  }
}

.navbar--arrow {
  cursor: pointer;
  padding: 20px 0px 10px 25px;
}

.arrow {
  height: 40px;
  width: auto;
}
