.accomm--section {
  width: 70vw;
  margin: auto;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

.accomm--section h1 {
  background-color: var(--white);
  margin-bottom: 10px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 10px;
  border-radius: 5px;
}
.accomm-scroll-link {
  background-color: var(--red);
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
}

.accomm-scroll-link:hover {
  background-color: var(--dark-red);
}
.accomm-scroll-link a {
  text-decoration: none;
  /* font-size: var(--body-heading-size); */
  font-size: 24px;
  font-weight: var(--body-heading-weight);
  color: var(--white);
}

.accomm--buttons {
  display: flex;
  justify-content: center;
}

.accom {
  position: relative;
  z-index: 1;
}

.housing-h4 {
  background-color: var(--white);
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 10px;
}
