.announcements--container {
  margin: auto;
  width: 50%;
  transition: height 2s ease;
  z-index: 10;
  padding-bottom: 30px;

  padding-top: 30px;

  /* padding-top: 500px; */
}

.announcement--card {
  background-color: var(--white);

  border-radius: 5px;
  margin: 0 0 30px;
  padding: 15px;
  z-index: 5;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.announcement--card a {
  color: var(--red);
  cursor: pointer;
}

.announcement--card a:hover {
  color: var(--dark-red);
}

.yes-hover {
  box-shadow: 0 6px 10px var(--navbar-shadow);
}

.yes-hover:hover {
  outline: 3px solid var(--blue);
}

.announcement--img-container {
  background-color: var(--white);
  height: 250px;
  max-height: 100%;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

.expand-container {
  width: auto;
  display: flex;
  /* flex-direction: row-reverse; */
  justify-content: center;
  color: var(--white);

  border-radius: 5px;
  cursor: pointer;
  font-weight: var(--section-heading-weight);
  margin-top: 10px;
  /* padding: 10px 0 0 0; */

  background-color: var(--red);
}

.expand-container:hover {
  background-color: var(--dark-red);
}

.expand-container p {
  /* background-color: var(--red); */
  padding: 2px 5px;
  border-radius: 5px;
}

/* .home-page {
  height: 100vh;
  background-image: url("../../public/images/ectc-background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  overflow: visible;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), var(--white)),
    url("../../public/images/ectc-background.jpg");
} */

.page {
  padding: 50px;
  margin-top: 80px;
  position: relative;
}

.page h1 {
  position: relative;
}
.home-page {
  margin-top: 80px;
  box-shadow: 0 -5px 10px #cfcece;
  min-height: 100vh;
  width: 100vw;
  background-color: var(--white);
  z-index: 6;
  position: relative;
  /* padding-top: 80px; */
  /* background-image: url("../../public/images/infinite-tile.jpg");
  background-size: contain;
  background-image: linear-gradient(
      0.25turn,
      rgba(0, 0, 0, 0),
      var(--white) 50%,
      rgba(0, 0, 0, 0)
    ),
    url("../../public/images/infinite-tile.jpg"); */
}

@keyframes fade-out {
  from {
    opacity: 100%;
  }
  to {
    opacity: 0%;
  }
}

/* 
@keyframes bye-bye {
  to {
    height: 0;
    width: 0;
  }
} */

.chappa-L-container {
  position: absolute;
  top: -10px;
  left: 0;
  height: 600px;
  max-width: auto;
}

.chappa-L {
  max-width: 90vw;
}

.chappa-R {
  max-width: 90vw;
}
img {
  height: 100%;
  width: auto;
}

.chappa-R-container {
  position: absolute;
  height: 500px;
  width: auto;
  right: 0px;
  top: 400px;
}

.kane-container {
  position: absolute;
  top: 900px;
  left: 0;
  height: 700px;
  max-width: auto;
}

.kane {
  max-width: 90vw;
}

.flowerHappi {
  max-width: 90vw;
}

.flowerCombo-container {
  position: absolute;
  height: 800px;
  width: auto;
  right: 0px;
  top: 1200px;
}

.flowerHappi-container {
  position: absolute;
  top: 1800px;
  left: 0;
  height: 600px;
  max-width: auto;
}

.chuBlue-container {
  position: absolute;
  top: 2300px;
  right: 0;
  height: 600px;
  max-width: auto;
}

.footer--container {
  width: 100vw;
  /* background-color: var(--dark-text); */
  color: var(--white);
  /* height: 200px; */
  /* padding: 20px 20px; */
  /* padding: 10px; */
  /* margin-top: 100px; */
  background: linear-gradient(var(--dark-text), black);
  box-shadow: 0px -2px 10px var(--navbar-shadow);
  position: absolute;
  bottom: 0;
}

.footer--home {
  width: 100vw;
  color: var(--white);
  height: auto;
  /* padding: 20px 0px; */
  background: linear-gradient(var(--dark-text), black);
  box-shadow: 0px -2px 10px var(--navbar-shadow);
  position: relative;
  z-index: 7;
}

.footer--home p {
  padding-left: 20px;
  padding-bottom: 20px;
}

.footer--container p {
  padding-left: 20px;
  padding-bottom: 20px;
}
.contact-info {
  padding: 0px 20px;
  display: flex;
  align-items: center;
}

.contact-info img {
  max-height: 30px;
  height: 100%;
  width: auto;
  cursor: pointer;
}

.contact-info a {
  text-decoration: none;
  color: var(--white);
}

.contact-info h3 {
  margin-left: 10px;
}
/*============== */
li {
  padding: 10px;
}
.contact-link {
  text-decoration: none;
}

.contact-icon {
  max-height: 30px;
  height: 100%;
  width: auto;
}

.contact-footer {
  list-style: none;
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  /* margin: 0px 0px 10px -18px; */
}

/* .sponsors-container {
  position: fixed;
  width: auto;
  background-color: var(--white);
  z-index: 100;
  box-shadow: 0px 5px 5px var(--navbar-shadow);
  padding: 10px;
  left: 50%;
  transform: translateX(-50%);
} */

.sponsors-close-button {
  cursor: pointer;
}

.sponsors--gray {
  position: fixed;
  height: 100vh;
  width: 100vw;
  /* background-color: rgba(1, 26, 45, 0.25); */
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 99;
  top: 0;
  left: 0;
}

.sponsors--container {
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* box-shadow: inset 0 0 10px var(--navbar-shadow); */
  z-index: 0;
  position: relative;
  overflow: clip;
  /* padding: 50px; */
  -webkit-transition: height 0.5s ease;
  transition: height 0.5s ease;
}

.sponsors--inner {
  -webkit-transition: height 0.5s ease;
  transition: height 0.5s ease;

  display: flex;
  padding: 50px 60px 30px 60px;
  margin-top: 80px;
  height: 450px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* position: fixed;
  top: 80px;
  transform: translateX(-50%);
  left: 50%; */
}

.sponsors--button {
  box-shadow: none;
  border: none;
  background-color: var(--red);
  color: var(--white);
  font-family: "proxima-nova", sans-serif;
  font-size: var(--body-text-size);
  font-weight: var(--section-heading-weight);
  padding: 8px 50px;
  cursor: pointer;
}

.sponsors--button:hover {
  background-color: var(--dark-red);
}

.sponsors--text {
  padding: 20px;
}

.sponsors--close {
  color: rgb(15, 105, 186);
  text-decoration: underline;
  padding: 15px;
  cursor: pointer;
}

.sponsors--logos-div {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  /* height: 150px; */
}

.sponsors--logo {
  height: auto;
  width: auto;
  max-height: 150px;
  max-width: 100%;
  padding: 0 20px;
}

.flower_both {
  position: fixed;
  top: 0;
  right: 0;
  height: 700px;
  /* z-index: 100; */
}

.sponsors-sticky--container {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 500;
  height: 80px;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  border: 4px solid rgb(150, 175, 208);
  padding: 0 10px;
}

.sponsors-sticky--logos-div {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  height: 60%;
}

.sponsors-sticky--logo {
  height: auto;
  width: auto;
  max-height: 100%;
  max-width: 100%;
  padding: 0 10px;
}

.sponsors-sticky--text-div {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: var(--body-heading-weight);
}

.sponsors-sticky--link {
  color: var(--red);
  cursor: pointer;
  text-decoration: underline;
}

.sponsors-sticky--link:hover {
  color: var(--dark-red);
}
.sponsors-card--container {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  margin: auto;
  width: 40%;
  padding: 5px 5px 5px 5px;
  margin: 10px;
  z-index: 1;
  box-shadow: 0px 5px 10px var(--navbar-shadow);
  border-radius: 5px;
}

.sponsors-card--img-container {
  background-color: var(--white);
  display: flex;
  justify-content: center;
  max-height: 40vh;
  max-width: 100%;
  padding: 10px;
}

.sponsors-card--img-container img {
  max-height: 100%;
  height: auto;
  max-width: 100%;
  width: auto;
}

.sponsors-card--text {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.sponsors-cards--container {
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  justify-content: center;
  height: auto;
  z-index: 100;
  margin-bottom: 30px;
}

@media (max-width: 700px) {
  .sponsors-cards--container {
    flex-direction: column;
    align-items: center;
  }
  .sponsors-card--container {
    width: 90%;
  }
  .sponsors-card--img-container {
    display: flex;
    justify-content: center;
  }
  .sponsors-card--img-container img {
    max-height: 100%;
    height: auto;
    width: auto;
  }
  .sponsors-card--img-container a {
    display: flex;
    justify-content: center;
  }
}

.chappa-L-container-sponsors {
  position: absolute;
  top: 100px;
  left: -80px;
  height: 600px;
  width: auto;
}

.chappa-R-container-sponsors {
  position: absolute;
  height: 600px;
  width: auto;
  right: -100px;
  top: 510px;
}

.footer--sponsors {
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  color: var(--dark-text);
  /* margin: 10px; */
  padding: 10px 20px;
  margin-bottom: 10px;
  /* box-shadow: 0px -2px 10px var(--navbar-shadow); */
}

.footer--sponsors h2 {
  color: var(--red);
}

.footer--sponsors img {
  height: 80px;
}

.footer--sponsors-logos-div {
  height: 100%;
  /* background-color: var(--white); */
}

@media (max-width: 800px) {
  .sponsors--text {
    font-size: 36px;
  }
  .sponsors--inner {
    padding-left: 2vw;
    padding-right: 2vw;
    text-align: center;
  }
  .announcements--container {
    width: 75%;
  }
  .footer--sponsors {
    display: block;
  }
}

@media (max-width: 500px) {
  .chappa-L-container {
    height: 400px;
  }
  .chappa-R-container {
    height: 400px;
    right: 0;
  }
  .footer--sponsors img {
    height: 50px;
  }
  /* .page {
    padding: 10px;
  } */
}

/* schedule */
.event--container {
  display: flex;
  justify-content: center;
  background-color: var(--blue);
  padding: 10px;
  /* width: 80vw; */
  /* margin: auto; */
  font-weight: var(--body-heading-weight);
}

.event--day-block {
  /* width: 60vw; */
  width: 900px;
  margin: auto;
  margin-bottom: 40px;
}

.event--name {
  width: 68%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.event--time {
  width: 17%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.event--loc {
  width: 15%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.blue {
  background-color: var(--light-blue);
  color: var(--dark-text);
}

.white {
  background-color: var(--white);
  color: var(--dark-text);
}

@media (max-width: 1000px) {
  .event--day-block {
    width: 650px;
  }
}

@media (max-width: 700px) {
  .event--day-block {
    width: 85vw;
  }
  .event--name {
    width: 60%;
  }
  .event--time {
    width: 40%;
  }
  .event--loc {
    width: 40%;
  }
}

.about p {
  margin: 10px 0;
}

.footer--sponsors span {
  color: var(--red);
  text-decoration: underline;
  cursor: pointer;
}

.footer--sponsors span:hover {
  color: var(--dark-red);
}

.about--page {
  width: 70%;
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  padding: 15px;
  box-shadow: 0 6px 10px var(--navbar-shadow);
  border-radius: 5px;
}

.about--page img {
  max-width: 100%;
  max-height: 50vh;
  width: auto;
  height: auto;
}

.about--text {
  margin-top: 15px;
}

.about--text a {
  color: var(--red);
}
.about--text a:hover {
  color: var(--dark-red);
}
.break {
  margin-top: 20px;
}

@media (max-width: 500px) {
  .about--page {
    width: 100%;
  }
}

.date-text {
  opacity: 0.5;
}

.annc-card-body {
  padding-top: 5px;
}

.annc--links {
  margin-top: 10px;
}

.sched--container {
  position: relative;
  z-index: 2;
  background-color: var(--white);
  display: inline-block;
}

.sched--outer {
  display: flex;
  justify-content: center;
}
